.login-logo {
  margin: 0 auto 24px auto;
  background-color: #fff;
  width: 100%;
  max-width: 220px;
}

.login-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/background-login.jpeg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 96vh;
}

.titulo-sisdev {
  font-size: 26px;
  text-align: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #fff;
  padding: 20px;
}

.container-cache-version {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #13cc9e;
  width: 100%;
  height: 4vh;
  color: #fff;
  font-size: 18px;
}

@media screen and (max-width: 500px) {
  .login-panel {
    display: flex;
    justify-content: center;
    align-items: start;
    background-image: none;
    background-color: #6d9a7a;
    padding: 50px;
    height: 100%;
  }

  .titulo-sisdev {
    font-size: 21px;
  }
}
