.container-loading {
  display: grid;
  place-items: center;
  min-height: 39vh;
}

.container-termo-apreensao-liberacao-impressao {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 900px) {
  .container-termo-apreensao-liberacao-impressao {
    display: none;
  }
}
