.container-card {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 10px;
  min-height: 20vh;
}

.container-space-memory-used {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

@media screen and (max-width: 500px) {
  .select-municipio {
    width: 150px;
  }
}
