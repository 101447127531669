.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  max-width: 600px;
}

.container-button {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 170px;
}

@media screen and (max-width: 900px) {
  .container {
    flex-direction: column;
    align-items: baseline;
  }
}
