.container-button {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-bottom: 20px;
}

.container-impressao {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.container-loading {
  display: grid;
  place-items: center;
  min-height: 39vh;
}

@media screen and (max-width: 900px) {
  .container-impressao {
    display: none;
  }
}
